export const environment = {
  npwp:"851968131603000",
  namaPerusahaan:"Amanah Kiriman Anda",
  namaProvider:"PT Mitra Inovasi Unggul",
  uiBarangKirimanDraftApiUrl : "https://prodserver1-ui-barangkiriman-draft-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBarangApiUrl : "https://prodserver1-ui-barangkiriman-barang-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBillingApiUrl : "https://prodserver1-ui-barangkiriman-billing-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanReportApiUrl : "https://prodserver1-ui-barangkiriman-report-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanUserApiUrl : "https://prodserver2-ui-barangkiriman-user-api-786567254893.asia-southeast2.run.app",
  firebaseConfig : {
    apiKey: "AIzaSyCS52GGOJBwNQIEFmjKsR2oHwqWpQd61hw",
    authDomain: "pibjk-v1-prod.firebaseapp.com",
    projectId: "pibjk-v1-prod",
    storageBucket: "pibjk-v1-prod.appspot.com",
    messagingSenderId: "786567254893",
    appId: "1:786567254893:web:8a0b22cab5319df766c064",
    measurementId: "G-FK53MBM6WZ"
  }
};
